import moment from 'moment-timezone';

jQuery( document ).ready( function ( $ ) {
	// Countdown
	var release_date = moment.tz( '2022-07-15 00:00:00', 'America/New_York' );
	$( '#countdown' ).countdown( release_date.toDate(), function ( event ) {
		$( this ).html( event.strftime( '<div><div><span>%D</span>:</div></div><div><div><span>%H</span>:</div></div><div><div><span>%M</span>:</div></div><div><div><span>%S</span></div></div>' ) );
	} );

} );
